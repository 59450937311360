.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-block {
  font-size: 1rem;
  border-bottom: 2px solid black;
}
.custom-block .custom-block-layer {
  font-weight: 700;
  margin-bottom: 0.4rem;
  text-align: center;
  border-bottom: 2px solid black;
}
.custom-block .custom-block-title {
  margin-bottom: -0.4rem;
}
.custom-block .custom-block-text {
  margin-bottom: -0.4rem;
}

.from-to-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.custom-block-button{
    padding: 9px 10px;
    display: inline-block;
    border: none;
    border-radius: 4px;
    /* background: rgba(0, 0, 0, 0.54); */
    background: #14375a;
    font-size: 12px;
    color: #fff;
    cursor: pointer;
}

.mapboxgl-popup-content{
  box-shadow: 0 3px 14px rgb(0 0 0 / 40%);
  border-radius: 12px;
}

.mapboxgl-popup-close-button{
  position: absolute;
    top: 2px;
    right: 3px;
    padding: 4px 4px 0 0;
    border: none;
    text-align: center;
    width: 18px;
    height: 14px;
    font: 16px/14px Tahoma, Verdana, sans-serif;
    color: #c3c3c3;
    text-decoration: none;
    font-weight: bold;
    background: transparent;
    background-color: transparent;
}

.custom-block-hover {
  font-size: 1rem;
  border-bottom: 2px solid rgb(204, 123, 41);
  border-top: 2px solid rgb(204, 123, 41);
}

#view-default-view{
  box-shadow: 0px 0px 10px 0px #9b9b9b,
        0 1px 1px 0 #9b9b9b,
        0 1px 3px 0 #9b9b9b;
}